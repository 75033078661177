@media @desktop {
  .catalog-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 0 2vw;

    .catalog-item {
      width: 100%;
      margin-top: 10px;
      .cover {
        width: 100%;
      }
    }
  }
}