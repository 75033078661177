.disclaimer {
  font-size: 0.7em;
  text-align: center;

  .app-label {
    display: block;
    margin: 0 auto;
  }

  /*.button-subscribe {
    .button-image;

    margin-top: 7px;
    //content: data-uri('./images/btn_subscribe_small.png');
    .image-content('./images/btn_subscribe_small.png');
    width: 170px;
    height: 30px;
  }*/
}