.page {
  &.profile {
    @media @desktop {

      .content.two-column {
        padding-left: 0;
        padding-right: 0;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: flex-start;
        justify-content: space-between;
        align-items: flex-start;

        .left, .right {
          flex-grow: 1;
          flex-basis: 0;
          min-width: 0;
        }

        & > :first-child {
          margin-top: 0;
        }

        .left {
          display: inline-block;
          float: left;
          //width: 50%;
          height: 100%;
          padding-left: @desktop-offset;

          .media-body {
            padding-top: 16px;
            padding-left: 8px;
          }

          .avatar {
            width: 128px;
            height: 128px;
          }

          .logout {
            margin-top: 1.5em;
            display: inline-block;
          }
        }

        .right {
          display: inline-block;
          float: left;
          //width: 50%;
          height: 100%;
          padding-right: @desktop-offset;

          .settings {
            margin: 24px 0 0 32px;
          }
        }
      }

      /*form {
        height: 100%;
      }*/

    }
  }
}