@import "../app-defenitions";

@karaoke-tcell-purple: #642887;
@karaoke-tcell-light-purple: #94288f;
@karaoke-tcell-dark-purple: #4e256b;
@karaoke-tcell-pink: #ed008c;
@karaoke-tcell-white: #ffffff;
@karaoke-tcell-grey: #f6f6f6;
@karaoke-tcell-dark-grey: #dbdbdb;
@karaoke-tcell-light-black: #555;
@karaoke-tcell-blue: #0083be;
@karaoke-tcell-dark-blue: #00639b;



@karaoke-tcell-color-dark: @karaoke-tcell-purple;
@karaoke-tcell-color-white: @karaoke-tcell-white;
@karaoke-tcell-color-green: @karaoke-tcell-pink;
@karaoke-tcell-color-violet: @karaoke-tcell-light-purple;
@karaoke-tcell-color-violet-dark: @karaoke-tcell-dark-purple;

@karaoke-primary-accent-color: @karaoke-tcell-pink;


@karaoke-text-color: #323232; // #5E5E5E
// ---------------------------------------------------------------------------------------------------------------------
// original
@karaoke-search-text-color: @karaoke-text-color;
@karaoke-search-placeholder-color: #8C8C8C;
@karaoke-search-border-color: #8C8C8C;
@karaoke-search-button-active-color: @karaoke-tcell-color-violet;


.karaoke-tcell-font-regular() {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
}

.karaoke-tcell-font-small() {
  .karaoke-tcell-font-regular();
  font-size: 11.2px;
}

.karaoke-tcell-font-large() {
  .karaoke-tcell-font-regular();
  font-size: 16px;
}

.karaoke-tcell-font-bold() {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.karaoke-tcell-button() {
  background-color: @karaoke-tcell-purple;
  color: @karaoke-tcell-white;
}

.karaoke-tcell-button-flat-new(@color: @karaoke-tcell-light-purple) {
  background-color: #ffffff88;
  color: @color;
  border-radius: 24px;
  border: none;
  outline:none;

  &:hover, &:active {
    background-color: #ffffffAA;
  }
}

// from app-*.less -----------------------------------------------------------------------------------------------------

@karaoke-bg-color: #f6f6f6;

@karaoke-mf-green: #01b58d;
@karaoke-mf-green-dark: #da;


@karaoke-mf-violet: white;

@karaoke-primary-accent-color: #f7f788;
@karaoke-t2w-color-white: #ffffff;
@karaoke-t2w-color-black: #25272d;
@karaoke-t2w-color-gray: #252525;
@karaoke-t2w-color-lightgray: #f5f5fa;
// ---------------------------------------------------------------------------------------------------------------------

@section-text-color: #ffffff;

//@section-main-bg-color: @karaoke-mf-green;
@section-main-bg-color: #c82271;
@section-main-border-color: @section-main-bg-color;

@section-catalog-bg-color: #c82271;
@section-catalog-border-color: @section-main-border-color;

@section-popular-bg-color: @section-main-bg-color;
@section-popular-border-color: @section-main-border-color;

@section-playlist-bg-color: @section-main-bg-color;
@section-playlist-border-color: @section-main-border-color;

@section-new-bg-color: @karaoke-tcell-white;
@section-new-border-color: @section-main-border-color;

@section-feedback-bg-color: @section-main-bg-color;
@section-feedback-border-color: @section-main-border-color;


//@section-main-hover-bg-color: @karaoke-mf-green-dark;
@section-main-hover-bg-color: @section-main-border-color;
@section-main-hover-border-color: @section-main-border-color;

@section-catalog-hover-bg-color: @section-catalog-border-color;
@section-catalog-hover-border-color: @section-catalog-border-color;

@section-popular-hover-bg-color: @section-popular-border-color;
@section-popular-hover-border-color: @section-popular-border-color;

@section-playlist-hover-bg-color: @section-playlist-border-color;
@section-playlist-hover-border-color: @section-playlist-border-color;

@section-new-hover-bg-color: @section-new-border-color;
@section-new-hover-border-color: @section-new-border-color;

@section-feedback-hover-bg-color: @section-feedback-border-color;
@section-feedback-hover-border-color: @section-feedback-border-color;

