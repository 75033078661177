@import "mixins";

// блоки заголовков разделов / соответствующих кнопок
.app-header-line {

  @karaoke-header-line-icon-size: 32px;

  display: block;
  padding-top: 5px;
  margin-top: 0.5em;
  white-space: normal; // firefox bug
  //z-index: 1;

  &, a& {
    color: @section-text-color;
  }

  .icon, .arrow {
    display: inline-block;
    position: relative;
    width: @karaoke-header-line-icon-size;
    height: @karaoke-header-line-icon-size;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-size: 100% 100%;
    }
  }

  .icon {
    float: left;
    margin-left: 6px;
  }

  .arrow {
    float: right;
    margin-left: -12px;

    &:before {
      background-image: data-uri('./images/ico_arrow_right.png');
    }
  }


  .text, .note {
    //font-family: Tele2Russia, Helvetica, Arial, serif;
    font-family: Helvetica, Arial, serif;
    font-size: 24px;
    font-variant: small-caps;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 12px;
    display: inline-block;
    //color: @section-text-color;
  }

  .note {
    float: right;
    padding-top: 6px;
    font-size: 16px;
    padding-right: 12px;

    /*&.btn {
      padding-top: 0;
    }*/
  }


  // иконки разделов

  &.section-main .icon:before {
    //background-image: data-uri('./images/ico_main.png');
  }

  &.section-catalog .icon:before {
    //background-image: data-uri('./images/ico_category_popular.png');
  }

  &.section-popular .icon:before {
    //background-image: data-uri('./images/ico_popular.png');
  }

  &.section-playlist .icon:before {
    //background-image: data-uri('./images/ico_playlist.png');
  }

  &.section-new .icon:before {
    //background-image: data-uri('./images/ico_new.png');
  }

  &.section-feedback .icon:before {
    //background-image: data-uri('./images/ico_feedback.png');
  }


  // app-icon - активные кнопки: расположение, дополнение стиля

  & .app-icon {
    float: right;
    margin-right: 5px;

    .press-glow();
  }

  & .app-icon.clear {
    background-image: data-uri('./images/ico_close.png');
  }


  // переопределение стиля кнопки (bootstrap)

  /*&.btn {
    border-radius: 0;
    padding: 5px 0 0 0;
    text-align: left;
  }*/



}
