@import "../../application/style/app.less";
//@import "app-tcell-font";
@import "app-tcell-defenition.less";

/* override begin */
.image-content-branding (@image-path) {
  //content: url(@image-path);

  background-image: url(@image-path);
  //background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}

.image-content-full-branding (@image-path) {
  width: image-width(@image-path);
  height: image-height(@image-path);

  .image-content-branding (@image-path);
}
/* override end */


// ---------------------------------------------------------------------------------------------------------------------

.app-header-line {
  position: relative;
  border: none;
  //border-bottom: 1px solid;
  z-index: 1;

  /*&:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;

    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    @color-old: rgb(74,54,156);
    @transparency: 0.75;
    @color1: rgba(74, 54, 156, @transparency);
    @color2: rgba(0, 105, 95, @transparency);
    @color3: rgba(59, 112, 28, @transparency);

    background: @color-old; !* Old browsers *!
    background: -moz-linear-gradient(left,  @color1 0%, @color2 55%, @color3 100%); !* FF3.6+ *!
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,@color1), color-stop(55%,@color2), color-stop(100%,@color3)); !* Chrome,Safari4+ *!
    background: -webkit-linear-gradient(left,  @color1 0%,@color2 55%,@color3 100%); !* Chrome10+,Safari5.1+ *!
    background: -o-linear-gradient(left,  @color1 0%,@color2 55%,@color3 100%); !* Opera 11.10+ *!
    background: -ms-linear-gradient(left,  @color1 0%,@color2 55%,@color3 100%); !* IE10+ *!
    background: linear-gradient(to right,  @color1 0%,@color2 55%,@color3 100%); !* W3C *!
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4a369c', endColorstr='#3b701c',GradientType=1 ); !* IE6-9 *!

    //background-image: url('./images/app-line-header.png');
    //background-repeat: repeat-y;
    //background-size: 100% auto;

  }*/

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;

    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    border: 1px solid #3DA379;
    opacity: 0.15;
  }

  .icon, .arrow {

    @karaoke-header-line-icon-size: 42px;
    top: -4px;

    width: @karaoke-header-line-icon-size;
    height: @karaoke-header-line-icon-size;
  }

  // иконки разделов

  &.section-main .icon:before {
    background-image: url('./images/ico_main.png');
  }

  &.section-catalog .icon:before {
    background-image: url('./images/ico_catalog.png');
  }

  &.section-popular .icon:before {
    background-image: url('./images/ico_popular.png');
  }

  &.section-playlist .icon:before {
    background-image: url('./images/ico_playlist.png');
  }

  &.section-new .icon:before {
    background-image: url('./images/ico_new.png');
  }

  //&.section-feedback .icon:before {
  //  background-image: url('../image/app/ico_feedback.png');
  //}

  .arrow {
    &:before {
      background-image: url('./images/ico_arrow_right.png');
    }
  }
}

.section-playlist {

  .subtext {
    display: block;
    font-size: 0.8em;

    margin-top: -6px; // margin-bottom: 6px
    margin-bottom: 6px;
  }

  .app-icon {
    position: absolute;
    right: 10px;
    top: 5px;

    &.clear {
      .image-content-branding("./images/ico_close.png");
    }

    &.filter {
      .image-content-branding("./images/ico_filter.png");
    }
  }
}


#background {

  //background-color: #b526e5;
  //background-blend-mode: hard-light;

  //background-color: rgba(255, 255, 255, 1);

  &:after {
    //content: none;
    //-webkit-filter: blur(4px);
    //background-color: rgba(0, 0, 0, 0.4);

    //background-color: rgba(255, 255, 255, 1);
    background-color: #ffffff;
  }
}

#background {

  background-color: #b526e5;
  background-blend-mode: hard-light;

  &:after {
    //content: none;
    //-webkit-filter: blur(4px);
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.cover {
  .description {
    background: rgba(93, 18, 153, 0.6);
  }
}

.loader {
  &:before {
    background-image: url("./images/loader_m.gif");
  }
}

.cover {
  .description {
    background: rgba(93, 18, 153, 0.6);
  }
}

.catalog-item .album {
  background: rgb(57, 16, 84);
}
#loader {
  background: rgba(92, 10, 150, 1);
}

.bootbox.modal .modal-dialog {
  .model-content {
    background-color: #590c89;
  }

  .modal-footer {
    border-top: 1px solid #282828;
    .btn.btn-primary {
      background-color: @karaoke-mf-green;
      border-color: @karaoke-mf-green;
    }
  }

  .modal-content .modal-body .modal-footer {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.bootbox input {
  color: #000000;
}

.song-item {
  background: #ffffff;
  border: 1px solid @karaoke-bg-color;

  .name {
    color: @karaoke-text-color;
  }

  .author {
    color: @karaoke-text-color;
  }
}

//~~~~~~~~~ Мои исполнения
song-item {
  &.event-play .play {
    background-image: url('./images/ico_play.png');
  }
  &.event-perf .play {
    background-image: url('./images/ico_record.png');
  }
  &.event-perf .playlist-action {
    background-image: url('./images/ico_close.png');
  }
}

.song-item {
  .play {
    background-image: url('./images/ico_mic.png');
  }

  .playlist-added {
    background-image: url('./images/ico_check.png');
  }

  .playlist-add {
    background-image: url('./images/ico_plus.png');
  }
}

.bottom-area {
  /*position: relative;

  &:before {
    /!*content: ' ';
    //z-index: 0;
    position: absolute;
    display: block;
    top: 0;
    background-color: red;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%*!/

    background: -moz-linear-gradient(top,  rgba(59,16,0,0) 0%, rgba(148,60,183,1) 50%, rgba(148,60,183,1) 100%); /!* FF3.6+ *!/
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(59,16,0,0)), color-stop(50%,rgba(148,60,183,1)), color-stop(100%,rgba(148,60,183,1))); /!* Chrome,Safari4+ *!/
    background: -webkit-linear-gradient(top,  rgba(59,16,0,0) 0%,rgba(148,60,183,1) 50%,rgba(148,60,183,1) 100%); /!* Chrome10+,Safari5.1+ *!/
    background: -o-linear-gradient(top,  rgba(59,16,0,0) 0%,rgba(148,60,183,1) 50%,rgba(148,60,183,1) 100%); /!* Opera 11.10+ *!/
    background: -ms-linear-gradient(top,  rgba(59,16,0,0) 0%,rgba(148,60,183,1) 50%,rgba(148,60,183,1) 100%); /!* IE10+ *!/
    background: linear-gradient(to bottom,  rgba(59,16,0,0) 0%,rgba(148,60,183,1) 50%,rgba(148,60,183,1) 100%); /!* W3C *!/
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003b1000', endColorstr='#943cb7',GradientType=0 ); /!* IE6-9 *!/
  }*/

  background: -moz-linear-gradient(top,  rgba(59,16,0,0) 0%, rgba(148,60,183,1) 50%, rgba(148,60,183,1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(59,16,0,0)), color-stop(50%,rgba(148,60,183,1)), color-stop(100%,rgba(148,60,183,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  rgba(59,16,0,0) 0%,rgba(148,60,183,1) 50%,rgba(148,60,183,1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  rgba(59,16,0,0) 0%,rgba(148,60,183,1) 50%,rgba(148,60,183,1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  rgba(59,16,0,0) 0%,rgba(148,60,183,1) 50%,rgba(148,60,183,1) 100%); /* IE10+ */
  background: linear-gradient(to bottom,  rgba(59,16,0,0) 0%,rgba(148,60,183,1) 50%,rgba(148,60,183,1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003b1000', endColorstr='#943cb7',GradientType=0 ); /* IE6-9 */  //.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12{

  //background: @karaoke-bg-color;


}

body #nprogress {
  @color: #943cb7;

  .bar {
    background: @color;
  }

  .peg {
    box-shadow: 0 0 10px @color, 0 0 5px @color;
  }

  .spinner-icon {
    border-top-color: @color;
    border-left-color: @color;
  }
}

.app-label.traffic-free {
  font-size: 0.95em;
  text-align: right;
  font-style: italic;
  margin-top: -17px;
  margin-right: -15px;
  color: rgb(8, 160, 98);
  color: white;
  text-shadow: 1px 1px 3px #2F2F2F;
}

.disclaimer {
  .app-label {
    //text-shadow: white 1px 1px 1px, white -1px -1px 1px, white 1px 0px 1px, white 0px 1px 1px, white 1px 1px 2px, white -1px -1px 2px, white 1px 0px 2px, white 0px 1px 2px, white 1px 1px 3px, white -1px -1px 3px, white 1px 0px 3px, white 0px 1px 3px;
    //color: #444;
    //text-shadow: 2px 2px 2px #333333, 0px 0px 3px #333333, 0px 0px 5px #333333
    text-shadow: none;
  }

  .button-subscribe {
    //content: url('./images/btn_subscribe_large.png');
    .image-content-branding('./images/btn_subscribe_small.png');
    height: 40px;
    width: 225px;
  }
}

.button-send {
  //content: url('./images/btn_send_large.png');
  .image-content-branding('./images/btn_send_small.png');
  height: 46px;
  width: 250px;
  font-size: 0;
}

.button-send, .disclaimer .button-subscribe {

  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;

  &:active {
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.art-play-finish {
  background-image: url('./images/art_play_finish.png');
}

.playlist-panel.affix {
  .app-header-line {
    background-color: #ffffff;
    border-bottom: 0;
  }
}

.promo-wide {

  @height: 150px;

  display: block;
  width: 100%;
  height: @height;
  background-color: #ffffff;
  //background-image: url('./images/promo-wide-back.png');
  background-position: center right;
  background-repeat: no-repeat;
  background-size: auto @height;

  .wrapper {
    display: block;
    width: 100%;
    height: 100%;
  }

  .cover {
    width: @height;
    height: @height;
  }

  .text-area {
    position: absolute;
    left: 150px;
    right: 0;
    top: 0;
    margin: 5px;

    .section, .author, .title {
      .app-label;
      text-shadow: none;
      color: black;
    }

    .section {
      font-size: 1.7em;
      margin-top: 10px;
      margin-bottom: 12px;
      height: 1em;
    }

    .author, .title {
      font-size: 1.1em;
      margin-top: 5px;
    }
  }

  .rn-carousel-control {
    display: none;
  }

  .rn-carousel-indicator {
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;

    @size: 8px;
    height: @size;
    //bottom: 0;
    bottom: @size;

    span {

      &.active {
        color: #dbdbdb;
        content: '';

        background-color: #642887;
        border: 1px white solid;
      }

      color: #dbdbdb;
      display: inline-block;
      font-size: 15px;

      text-indent: -9999px;
      width: @size;
      height: @size;
      border-radius: @size/2;

      margin: 0 1px;
      margin-bottom: @size;
      background-color: #dbdbdb;
      border: 1px #dbdbdb solid;
    }
  }

  .cover {
    //@color: @karaoke-mf-violet;
    @color1: #004F90;
    @color2: #5b94ff;

    //box-shadow: @color1 0 0 50px, @color2 0 0 75px; // optimization

    &:hover {
      box-shadow: @color1 0 0 50px, @color2 0 0 75px;
    }
  }
}

.feedback-block, .terms-block {
  color: white;
}

.song-item {
  &.ripple-effect .ink {
    background: @karaoke-mf-green;
  }
}

/* -- Ripple effect -------------------------------- */
.ripple-effect {
  position: relative;
  overflow: hidden;
  //-webkit-transform: translate3d(0, 0, 0);
}
.ink {
  display: block;
  position: absolute;
  pointer-events: none;
  border-radius: 50%;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  background: #fff;
  opacity: 1;
}
.ink.animate {
  -webkit-animation: ripple .5s linear;
  -moz-animation: ripple .5s linear;
  -ms-animation: ripple .5s linear;
  -o-animation: ripple .5s linear;
  animation: ripple .5s linear;
}
@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}
@-webkit-keyframes ripple {
  100% {
    opacity: 0;
    -webkit-transform: scale(2.5);
    transform: scale(2.5);
  }
}
@-moz-keyframes ripple {
  100% {
    opacity: 0;
    -moz-transform: scale(2.5);
    transform: scale(2.5);
  }
}
@-ms-keyframes ripple {
  100% {
    opacity: 0;
    -ms-transform: scale(2.5);
    transform: scale(2.5);
  }
}
@-o-keyframes ripple {
  100% {
    opacity: 0;
    -o-transform: scale(2.5);
    transform: scale(2.5);
  }
}


.karplayer {

  @karaoke-text: #00c16e;

  .control-block {
    background-color: rgba(55, 15, 82, 0.55);
  }

  .progress-bar {
    background-color: @karaoke-mf-green;
  }

  .kar-text.ready {

    .karaokeBlock.speakingBlock span[data-begin].speaking {
      color: @karaoke-text;
    }

    .karaokeBlock.speakingBlock span[data-begin].speaking.finish {
      color: @karaoke-text;
    }

    span[data-begin].speaking {
      color:@karaoke-text;
    }

    span[data-begin].speaking.finish {
      color: @karaoke-text;
    }
  }

  a.play-next:hover {
    background: @karaoke-mf-green;
  }

  .kar-button-main {

    .btn-primary {
      background-color: @karaoke-mf-green;
      border-color: @karaoke-mf-green;
    }

    .stop {
      background-image: url("./images/player_play.png");
    }

    .pause {
      background-image: url("./images/player_play.png");
    }

    .play {
      background-image: url("./images/player_stop.png");
    }

    .load {
      background-image: url("./images/player_wait.png");
    }
  }
}


.pagination>li>a, .pagination>li>span {

  border-radius: 25px !important;
  min-width: 30px;
  padding: 4px 0;
  margin: 0 2px;

  background-color: white;
  color: rgb(86, 6, 139);
  border: 1px solid #55058A;
}

.pagination>.disabled>a, .pagination>.disabled>a:focus, .pagination>.disabled>a:hover, .pagination>.disabled>span, .pagination>.disabled>span:focus, .pagination>.disabled>span:hover {
  color: #939393;
  background-color: #943CB7;
  border-color: #939393;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
  color: #fff;
  background-color: #00975E;
}

.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
  color: #ffffff;
  background-color: #56068B;
}

.bootbox.modal.modal-auth, .bootbox.modal.modal-subscribe-song {

  .modal-dialog {

    margin-top: 25%;

    .modal-content {
      margin: 0 20px;

      background-color: white;
      color: #666;
      text-align: center;
      border-radius: 0;
      font-weight: 200;

      .bootbox-close-button {
        margin-right: -10px;
        background-color: @karaoke-mf-violet;
        padding: 4px 9px 8px;
        line-height: 20px;
        font-size: 20px;
        border-radius: 30px;
        width: 30px;
        height: 30px;
        display: block;
        opacity: 1;
      }

      .bootbox-body {
        padding-top: 1.5em;
      }

      .modal-footer {
        text-align: center;
        border-top: 1px solid #EDEDED;
        padding: 10px 0;

        .btn {
          width: 45%;
          border-radius: 0;

          @media (max-width: 420px) {

            &:before, &:after {
              //margin: -12px 0;
              margin: -12px 12px -12px 0;
            }

            // чтобы не перевешивало
            &:after {
              display: none;
            }
          }
        }

        .btn.btn-success, .btn.btn-subscribe-main, .btn.btn-subscribe-plus {
          background-color: @karaoke-mf-green;
          border-color: @karaoke-mf-green;
          border-bottom-color: @karaoke-mf-green-dark;
          border-bottom-width: 3px;
        }

        .btn.btn-primary {
          background-color: @karaoke-mf-violet;
          border-color: @karaoke-mf-violet;
          border-bottom-color: #500088;
          border-bottom-width: 3px;
        }
      }

      b {
        font-size: 1.1em;
        color: #333;
      }
    }
  }
}

.modal-subscribe-song.bootbox.modal {
  .modal-dialog {
    .modal-content {
      .modal-footer {
        .btn {
          width: auto;
          min-width: 40%;

          &:before, &:after {
            display: inline-block;
            content: "";
            height: 32px;
            width: 32px;
            background-size: cover;
            position: relative;
            //margin: -12px 12px;
          }

          // чтобы не перевешивало
          &:after {
            width: 0;
          }
        }

        .btn.btn-success, .btn.btn-subscribe-main, .btn.btn-subscribe-plus {
          &:before {
            .image-content-branding('./images/ico_btn_sub_accept.png');
          }
        }

        .btn.btn-primary {
          &:before {
            .image-content-branding('./images/ico_btn_sub_cancel.png');
          }
        }
      }
    }
  }
}

// фикс заголовка меню MegafonPro для совместимости с табличной вёрсткой сервиса
#main .page-header-main-content {
  //.template-header;

  display: table-row;
  width: 100%;
  //height: 0;
  height: 50px;

  &:after {
    content: "";
    display: block;
    border-bottom: 1px solid #282828;
    height: 20px;
  }

  .page-sliding-aside-handle {
    position: static;
    //transform: translate(100%, -150%);
    transform: translate(100%, 50%);
  }

  .logo {
    margin-left: 35px;
    margin-top: 10px;
    height: 0;
  }

  .restriction-badge {
    position: static;
    float: right;
    margin-top: 25px;
    margin-right: 15px;
  }
}

.warn {
  font-size: 1.1em;
  color: @karaoke-tcell-pink;
  font-weight: bold;
  line-height: 2em;
}

@import "app-tcell-special.less";

//           DESKTOP
@import "element-disclaimer";
@import "element-catalog";
@import "element-cover";
@import "../../application/style/bootstrap/variables";
@import "../../external/bootstrap/less/mixins";
@import "../../external/bootstrap/less/pagination";

@desktop-offset: 52px;

// patch - выравниваение заголовков по левому краю
#main {
  .app-header-line {
    text-align: left;
    padding: 5px 14px;
  }
  .affix-top .app-header-line {
    margin-left: -32px;
  }
}

// desktop
@media @desktop {

  @section-catalog-bg-color: @karaoke-tcell-white !important;

  // todo: hack
  /*.page-block-main > .col-md-6.col-md-offset-3,
  .page-block > .col-md-6.col-md-offset-3,
  .page-block-main > .content,
  .page-block > .content {
    margin-left: 0;
    margin-right: 0;
    padding-left: @desktop-offset;
    padding-right: @desktop-offset;
    width: 100% !important;
    float: left;
    position: relative;
    min-height: 1px;
  }*/

  #main .app-header-line {
    padding: 16px 14px;
  }

  .song-batch-carousel {
    display: none;
  }

  .promo-wide {
    display: none;
  }

}

@brand-primary: @karaoke-primary-accent-color;
@brand-info: @karaoke-primary-accent-color;

@pagination-color: @karaoke-t2w-color-gray;
@pagination-bg: @karaoke-t2w-color-white;
@pagination-border: @karaoke-t2w-color-lightgray;

@pagination-active-color: @karaoke-t2w-color-white;
@pagination-active-bg: @karaoke-search-border-color; /*@brand-primary;*/
@pagination-active-border: @karaoke-search-border-color; /*@brand-primary;*/

@pagination-disabled-color: #bebebe;
@pagination-disabled-bg: @karaoke-t2w-color-lightgray;
@pagination-disabled-border: @karaoke-t2w-color-lightgray;

@border-radius-base: 0;
@border-radius-large: @border-radius-base;
@border-radius-small: @border-radius-base;

.page-history .section-playlist.secondary {
  display: none;
}

@media @desktop {


  .page-history {
    .section-playlist.app-header-line {
      .subtext, .filter {
        display: none;
      }
    }

    .section-playlist.secondary {
      display: block;
      margin-bottom: 18px;

      & > * {
        display: inline-block;
        width: 250px;
      }

      .label {
        position: relative;
        color: #828282;
        padding-left: 58px;
        text-align: left;
        font-weight: normal;
        font-size: 1em;

        .app-icon {
          left: 12px;
          top: -5px;
          right: auto;
          opacity: 0.6;
          float: left;
        }
      }

      button-row {
        display: inline-block;
        width: 250px;
        .name {
          white-space: nowrap;
        }

        &.active .song-item {
          background-color: @karaoke-tcell-purple;
        }
      }
    }

    .grid-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      grid-gap: 0 2vw;
    }
  }
}

@media @desktop {
  .page {
    margin-bottom: 25px;
  }

  .bootbox.modal {
    .modal-footer {
      text-align: center;
      .btn, .btn + .btn {
        display: inline-block;
        margin: 0 20px 0 0;
        min-width: 224px;
      }
    }
  }

  .song-item {
    .name {
      margin-left: 0px;
      text-align: left;
    }
  }

  .karplayer .wrapper {
    background: @karaoke-tcell-dark-grey !important;
  }

  .karplayer .kar-text {
    color: @karaoke-tcell-dark-purple !important;
  }

  .karplayer player-song-copyrights {
    color: @karaoke-tcell-dark-purple !important;
  }

  player-song-title > .media {
    background: transparent !important;
  }

  .catalog-item .album {
    background: @karaoke-tcell-white;
  }

  .bottom-area {
    background: @karaoke-tcell-white !important;
  }

  .search {
      margin-top: 10px !important;
  }
}

@media @desktop {
  .mobile {
    display: none !important;
  }
}
@media @phone {
  .desktop {
    display: none !important;
  }
}

.profile {

  @media @desktop {

    form {
      height: 100%;
    }

    .left {
      display: inline-block;
      float: left;
      width: 50%;
      height: 100%;
      background-color: @karaoke-t2w-color-lightgray;
      padding-left: @desktop-offset;
      color: @karaoke-tcell-light-black;

      .media-body {
        padding-top: 16px;
        padding-left: 8px;
      }

      .avatar {
        width: 128px;
        height: 128px;
      }

      .logout {
        margin-top: 1.5em;
        display: inline-block;
      }

      .title {
        font-size: 20px;
      }

      .text {
        font-size: 25px;
      }

      .comment {
        font-size: 15px;
      }
    }

    .right {
      display: inline-block;
      float: left;
      width: 50%;
      height: 100%;
      padding-right: @desktop-offset;
      color: @karaoke-tcell-light-black;

      .settings {
        margin: 124px 0 0 32px;
      }
    }
  }
}

.siri-container {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  canvas {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
  }
}

@media @desktop {
  .karplayer {

    .siri-container {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      //bottom: 0;
      bottom: 20%;
    }

    .wrapper {
      position: relative;
      background: @karaoke-t2w-color-black;
      padding: 0 @desktop-offset;
    }

    /*.wrapper > * {
      position: absolute; top:0; right: 0; bottom: 0; left: 0;
    }*/

    .wrapper:after {
      content: '';
      clear: both;
    }

    player-song-copyrights {
      display: inline-block;
      margin-top: 28px;
      margin-left: 18px;
      color: @karaoke-t2w-color-lightgray;
    }

    .kar-text {
      color: @karaoke-t2w-color-white;
    }
  }

  #passage-text.passage {
    transform: scale(0.75) translate(0, 50%);
    margin-bottom: 23px;
  }
}


/*.karplayer .control-block.desktop {
  margin: 0;
  padding: 0;

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  player-song-title {
    position: absolute;
  }

  .media-body {
    .artist {
      margin-top: 10px;

      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: bold;
      line-height: 1.5em;
      color: @karaoke-search-placeholder-color;
    }
    .song {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: bold;
      line-height: 1.5em;
      color: @karaoke-t2w-color-white;
    }
  }

  .media.control-block {
    margin: 0;
    padding: 0;
  }

  .control-block .cover {
    border-radius: 0;
    width: 64px;
    height: 64px;
  }

  .play-pause {
    width: 54px;
    margin: 5px;
  }

  .record {
    margin-top: 0;
    margin-left: 0;
    float: right;
  }

  .play-end, .play-next {
    float: right;
    padding: 0;
    margin: 0;

    .pb-label {
      display: none;
    }
  }

  .play-end .pb-icon {
    .image-content-branding('./images/player_finish.png');
    filter: invert(1);
  }

  .play-next .pb-icon {
    .image-content-branding('./images/player_next.png');
    filter: invert(1);
  }

  .record .pb-icon.state-off {
    filter: invert(1);
  }

  .kar-progress {
    !*bottom: 0;*!
    bottom: -10px;
    .progress {
      margin: 0;
      height: 10px;
      border-radius: 0;
    }
  }
}*/

.player-finish {
  .score {
    font-size: 1.2em
  }

  .upload {
    font-size: 0.8em; color: #6f6f6f;
  }
}

player-button {
  .pb-icon {
    &.state-stop {
      background-image: url("../../application/karaoke/player/components/tcell/player_play.png");
    }

    &.state-pause {
      background-image: url("../../application/karaoke/player/components/tcell/player_play.png");
    }

    &.state-play {
      background-image: url("../../application/karaoke/player/components/tcell/player_stop.png");
    }

    &.state-load {
      background-image: url("../../application/karaoke/player/components/tcell/player_wait.png");
      animation: fa-spin 2s infinite linear;
    }
  }

  .pb-label {
    .karaoke-tcell-font-small();
    color: @karaoke-tcell-color-white;
  }
}

player-button.sing .pb-icon {
  background-image: url("../../application/karaoke/player/components/tcell/player_sing.png");
}

player-button.record {
  .pb-icon {
    &.state-disabled {
      background-image: url("../../application/karaoke/player/components/tcell/recorder_disabled.png");
    }

    &.state-off {
      background-image: url("../../application/karaoke/player/components/tcell/recorder_disabled.png");
    }

    &.state-on {
      background-image: url("../../application/karaoke/player/components/tcell/recorder_enabled.png");
    }

    &.state-duet {
      background-image: url("../../application/karaoke/player/components/tcell/recorder_duet_enabled.png");
    }

    &.state-battle {
      background-image: url("../../application/karaoke/player/components/tcell/recorder_battle_enabled.png");
    }
  }
}

@media @desktop {

  body {
    .icon-volume-user {
      .image-content-branding("./images/ico_volume_user@2x.png");
    }

    .icon-volume-phone {
      .image-content-branding("./images/ico_volume_phone@2x.png");
    }
  }

  .player-content {
    .player-song-copyright {
      font-size: 11.2px;
      line-height: 4px;
    }
  }

  #background {
    background: @karaoke-t2w-color-white;
  }

  #background:after {
    background: @karaoke-t2w-color-white;
  }

  .content {
    background: @karaoke-t2w-color-white;
  }

  a {
    color: @karaoke-tcell-purple !important;
  }

  .song-item.btn.ripple-effect:hover {
    box-shadow: 0 0 3em #1176ee;
  }

  player-button {

    .pb-icon {
      //background: red;
    }

    .pb-label {
      //font-family: 'Tele2TextSansWebSHORTBold', sans-serif;
      //color: #8F9399;
      font-weight: bold;
      font-style: normal;
      font-size: 0.75em;
      text-align: center;

      font-family: "Helvetica Neue", Helvetica, arial, serif;
      color: #1F2229;
    }
  }

  .jumbotron {
    background: @karaoke-tcell-grey;
    border: @karaoke-tcell-dark-grey;
    color: @karaoke-tcell-light-black;
  }

  player-button .pb-icon {

    &.state-stop {
      background-image: url("./images/player_play.png");
    }

    &.state-pause {
      background-image: url("./images/player_play.png");
    }

    &.state-play {
      background-image: url("./images/player_stop.png");
    }

    &.state-load {
      background-image: url("./images/player_wait.png");
      animation: fa-spin 2s infinite linear;
    }
  }


  player-button.sing .pb-icon {
    background-image: url("./images/player_sing.png");
  }

  player-button.record {
    float: left;
    margin-top: -68px;
    margin-left: 16px;

    .pb-label {
      display: none;
    }
  }

  .modal-title {
    color: @karaoke-tcell-light-purple;
  }

  button-row.record-mode {

    .icon {
      width: 42px;
      height: 42px;
      display: block;
      float: left;
      margin-left: 10px;
      margin-right: 10px;
      background-size: 100%;
    }

    & .state-battle .icon {
      background-image: url("./images/ic_mode_record_battle.png");
    }

    & .state-duet .icon {
      background-image: url("./images/ic_mode_record_duet.png");
    }

    & .state-off .icon {
      background-image: url("./images/ic_mode_record_off.png");
    }

    & .state-on .icon {
      background-image: url("./images/ic_mode_record_on.png");
    }
  }

  .app-header-line:after {
    border: 0;
    padding-left: 0;
    margin-left: 0;
  }

  .app-header-line {
    font-weight: bold;
  }

  .section-main,
  .section-main:hover,
  .section-main:active,
  .section-catalog,
  .section-catalog:hover,
  .section-catalog:active,
  .section-new,
  .section-new:hover,
  .section-new:active,
  .section-popular,
  .section-popular:hover,
  .section-popular:active,
  .item,
  .item:hover,
  .item:active,
  .section-playlist,
  .section-playlist:hover,
  .section-playlist:active {
    color: @karaoke-tcell-purple !important;
    border-color: @karaoke-tcell-white !important;
    background-color: @karaoke-tcell-white !important;
    font-weight: bold !important;
  }

  .app-header-line > .text {
    color: @karaoke-tcell-dark-purple;
  }

  .app-header-line > .icon {
    display: none;
  }

  .section-feedback {
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .modal-content {
    .bootbox-body a {
      color: @karaoke-tcell-white !important;
    }

    .song-item {
      background: @karaoke-tcell-white;
      padding-bottom: 10px;
      border: none;
      margin-bottom: 15px;
    }

    .song-item a {
      background: @karaoke-tcell-dark-grey;
      padding-bottom: 10px;
      border: none;
    }
  }

  player-button {
    width: 68px;

    .pb-icon {
      //background: red;
    }

    .pb-label {
      //font-family: 'Tele2TextSansWebSHORTBold', sans-serif;
      //color: #8F9399;
      font-weight: bold;
      font-style: normal;
      font-size: 0.75em;
      text-align: center;

      font-family: "Helvetica Neue", Helvetica, arial, serif;
      color: #1F2229;
    }
  }

  player-button .pb-icon {

    &.state-stop {
      background-image: url("./images/player_play.png");
    }

    &.state-pause {
      background-image: url("./images/player_play.png");
    }

    &.state-play {
      background-image: url("./images/player_stop.png");
    }

    &.state-load {
      background-image: url("./images/player_wait.png");
      animation: fa-spin 2s infinite linear;
    }
  }


  player-button.sing .pb-icon {
    background-image: url("./images/player_sing.png");
  }

  player-button.record {
    float: left;
    margin-top: -68px;
    margin-left: 16px;

    .pb-label {
      display: none;
    }
  }

  player-button.record .pb-icon {

    &.state-disabled {
      background-image: url("./images/recorder_disabled.png");
    }

    &.state-off {
      background-image: url("./images/recorder_disabled.png");
    }

    &.state-on {
      background-image: url("./images/recorder_enabled.png");
    }

    &.state-duet {
      background-image: url("./images/recoder_duet_enabled.png");
    }

    &.state-battle {
      background-image: url("./images/recoder_battle_enabled.png");
    }
  }

  .section-catalog:focus {
    outline: none;
  }

  .section-catalog:active {
    box-shadow: none;
  }

  .section-catalog span {
    color: @karaoke-tcell-purple;
  }

  .player-finish {
    .block-first {
      background-color: @karaoke-tcell-white;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;


      .art-play-finish {
        margin-left: 0;
        margin-right: 0;
      }

      .score, .upload {
        color: @karaoke-tcell-dark-purple;
        text-align: left;
        margin-left: 32px;
      }
    }

    .block-second {
      background-color: @karaoke-t2w-color-lightgray;
    }

    .art-play-finish {
      background-color: white;
      border-radius: 50%;
      margin-top: 16px;
      border: 16px white solid;
      box-sizing: content-box;
      overflow: hidden;

      .score {
        margin-left: 0;
      }
    }
  }

  .history-mode {
    .name {
      text-align: center !important;
    }
  }

  .page-playback {

    .karplayer {
      height: auto;
      min-height: auto;
      background-color: @karaoke-tcell-dark-grey;
      position: relative;
      color: @karaoke-tcell-dark-purple;
    }

    .siri-container {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      //bottom: 0;
      bottom: 20%;
    }

    player-song-copyrights {
      display: inline-block;
      margin-top: 28px;
      margin-left: 18px + @desktop-offset;
      color: @karaoke-t2w-color-lightgray;
      font-size: 11.2px;
      line-height: 4px;
    }

    player-song-progress {
      .progress {
        margin: 0;
        height: 10px;
        border-radius: 0;
      }
    }

    .karplayer > .control-block {
      margin: 0 @desktop-offset;
      padding: 0px 7px 0px 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-content: center;
      position: relative;
      margin-top: 240px;

      player-song-title {
        margin-right: 64px;
      }

      .play-pause {
        position: absolute;
        left: 0;
        bottom: 3px;

        width: 54px;
        margin: 5px;
      }
    }

    .share {
      display: block;
      background-color: @karaoke-t2w-color-lightgray;
      text-align: center;
      padding: 5px 0;
      span {
        line-height: 32px;
        margin: 0 16px;
      }

      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }

    .sing-label {
      width: 64px; margin: 0 12px 0 64px;
      color: white;
    }

    .icon-volume-user, .icon-volume-phone {
      filter: invert(1);
    }

    .sing {
      filter: invert(1);
      a {
        height: 64px;
        min-width: 64px;
      }
    }

    .media.control-block {
      margin: 0;
      padding: 0;
      white-space: nowrap;

      .cover {
        border-radius: 0;
        width: 64px;
        height: 64px;
      }

      .media-body {
        width: auto;
        .artist {
          margin-top: 10px;

          font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-weight: bold;
          line-height: 1.5em;
          color: @karaoke-search-placeholder-color;
        }
        .song {
          font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-weight: bold;
          line-height: 1.5em;
          color: @karaoke-t2w-color-white;
        }
      }
    }

    .rzslider .rz-pointer {
      background-color: white;
      border: 8px solid black;
    }

  }


  body player-button .pb-icon {

    &.state-stop {
      background-image: url("./images/player_play_desktop.png");
    }

    &.state-pause {
      background-image: url("./images/player_play_desktop.png");
    }

    &.state-play {
      background-image: url("./images/player_stop_desktop.png");
    }
  }
}

.bootbox.modal .modal-dialog, .modal-dialog {

  //margin: @karaoke-dim-page-padding-mobile @karaoke-dim-page-padding-mobile 0 @karaoke-dim-page-padding-mobile;

  .modal-content {
    margin: 0;

    border-radius: 8px;
    border: 1px solid lightgrey;

    background-color: white;
    color: @karaoke-tcell-light-black;
    text-align: left;
    //font-weight: 200;

    font-size: 1.1em;

    a, .bootbox-body a {
      color: @karaoke-tcell-light-purple !important; // todo
      font-weight: normal;
    }

    hr {
      border-top: 1px solid @karaoke-tcell-dark-grey;
    }

    .bootbox-close-button {
      .hide-internal-text();

      //background-color: @karaoke-mf-violet;
      background-image: url("./images/ico_close.png");
      background-size: 32px;
      padding: 4px 9px 8px;
      line-height: 20px;
      font-size: 20px;
      border-radius: 16px;
      width: 32px;
      height: 32px;
      display: block;
      opacity: 1;
    }

    .bootbox-body {
      padding-top: 1.5em;
    }

    .modal-footer {
      text-align: center;
      border-top: 0;
      //border-top: 1px solid #EDEDED;
      padding: 10px 0;
      margin-bottom: 10px;

      border-top: 1px solid @karaoke-tcell-grey;

      .btn {
        .karaoke-tcell-button-flat-new(@karaoke-tcell-light-black);
        width: auto;
        min-width: 40%;

        &:before, &:after {
          display: none;
        }
      }

      .btn.btn-success, .btn.btn-subscribe-main, .btn.btn-subscribe-plus {
        .karaoke-tcell-button-flat-new();

        &:before, &:after {
          display: none;
        }
      }

      .btn.btn-primary {
        .karaoke-tcell-button-flat-new();

        &:before, &:after {
          display: none;
        }
      }
    }

    b {
      font-size: 1.1em;
      color: #333;
    }
  }

  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: @karaoke-tcell-light-black;
  }
}
