@cover-size: 120px;

.cover {
  .loader;

  background-size: cover;
  width: @cover-size;
  height: @cover-size;
  margin: auto;
  display: inline-block;
  position: relative;

  .description {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    text-align: left;
    background: rgba(0, 0, 0, 0.6);
    padding: 4px 4px;

    .author {
      font-size: 0.8em;
      line-height: 0.9em;
      margin-bottom: 4px;
      display: block;
    }

    .title {
      font-size: 1em;
      line-height: 0.9em;
      display: block;
    }
  }
}

.cover {
  .description {
    color: @karaoke-tcell-purple;
    background: rgba(255, 255, 255, 0.7);

    .author {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      line-height: 1.1em;
    }

    .title {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      line-height: 1.1em;
      font-weight: bold;
    }
  }
}

@media @desktop {
  .cover {
    .description {
      padding: 12px;

      display:flex;
      flex-direction: column-reverse;
      .author {
        margin-top: 4px;
        margin-bottom: 0;
      }

      .author, .title {
        font-size: 16px;
      }
    }
  }
}
