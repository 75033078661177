.loader {

  @size: 120px;

  width: @size;
  height: @size;
  margin: auto;
  display: inline-block;
  position: relative;

  &:before {
    display: block;
    position: relative;
    z-index: -1;
    background-image: data-uri("./images/loader_m.gif");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto;
    width: 100%;
    height: 100%;
    content: '';
  }
}

.fullscreen .loader {
  margin-top: 0.7em;
}

#loader {
  .fullscreen();

  background: rgba(0, 0, 0, 0.8);
  z-index: 150;
  font-size: 1.2em;
}

body #loader .loader-app, body.ng-scope #loader .loader-page {
  display: block;
}

body #loader .loader-page, body.ng-scope #loader .loader-app {
  display: none;
}


// оповещение о загрузке каталога, песен и пр.
.loading {
  width: 100%;
  font-size: 1.2em;
  line-height: 1.2em;
  text-align: center;
  //background: rgba(0, 0, 0, 0.6);
  padding: 0.5em 0;

  margin: 1.2em 0;

  //border: 1px solid white;

  animation: loading-border 5s;
  -moz-animation: loading-border 5s infinite; /* Firefox */
  -webkit-animation: loading-border 5s infinite; /* Safari and Chrome */

  .anim {
    display: block;
    margin: 10px auto 0;
    width: 50px;
    height: 10px;
    background: data-uri("./images/loading_dots.gif");
    background-size: 50px 10px;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.loading.ng-hide-add, .loading.ng-hide-remove {
  transition: 0.5s linear all;
}

.loading.ng-hide-remove { height: 0; }
.loading.ng-hide-remove.ng-hide-remove-active { height: 0; }

@keyframes loading-border {
  0% {
    border-color: white;
  }
  50% {
    border-color: lightgray;
  }
  100% {
    border-color: white;
  }
}

@-moz-keyframes loading-border /* Firefox */
{
  0% {
    border-color: white;
  }
  50% {
    border-color: lightgray;
  }
  100% {
    border-color: white;
  }
}

@-webkit-keyframes loading-border /* Safari and Chrome */
{
  0% {
    border-color: white;
  }
  50% {
    border-color: lightgray;
  }
  100% {
    border-color: white;
  }
}