@import "../application/style/bootstrap/variables";
@import "../external/bootstrap/less/mixins";
@import "../application/karaoke/common/media";

@karaoke-dim-ident-page: 64px;
@karaoke-dim-page-padding-mobile: 15px;

@desktop-offset: 52px; // todo: from tele2

.hide-internal-text() {
  font: ~"0/0" a;
  color: transparent;
  text-shadow: none;
}

.nprogress(@nprogress-color) {
  body #nprogress {
    .bar {
      background: @nprogress-color;
    }
    .peg {
      box-shadow: 0 0 10px @nprogress-color, 0 0 5px @nprogress-color;
    }
    .spinner {
      top: 8px;
      right: 12px;
    }
    .spinner-icon {
      border-top-color: @nprogress-color;
      border-left-color: @nprogress-color;

      width: 32px;
      height: 32px;
    }
  }
}

// legacy

.button-image() {
  display: block;
  width: 220px;
  height: auto;
  //height: 38px;
  //background-size: 100% auto;
  //background-repeat: no-repeat;
  //background-position: center center;

  box-sizing: content-box;
  padding: 5px;

  border-radius: 5px;

  &:active {
    background-color: rgba(255, 255, 255, 0.8);
    //border-radius: 5px;
  }
}