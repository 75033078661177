@import "mixins";

.app-header {

  .center-vertical-block() {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  @top: 4px;
  @logo-height: 40px;
  @icon-size: 42px;

  position: relative;
  height: @logo-height + @top*2;
  text-align: center;
  //font-style: italic;

  //margin-bottom: 5px;

  .logo {
    display: inline-block;
    max-width: 100%;
    max-height: @logo-height;
    margin: 0 auto;
    margin-top: @top;
    padding: 1px @icon-size 1px @icon-size;
    //content: data-uri('./images/title_shadow.png');
    //.image-content-full('./images/title_shadow.png');
    //background-size: auto 100%;
    width: 100%;
    //background-clip: content-box;

    //.image-content-branding('./images/title.png');
    background: none;
    text-align: left;

    &:before, &:after {
      content: "КАРАОКЕ";
      display: inline-block;
      font-weight: bold;
      font-size: 20px;
      letter-spacing: 0;
      text-align: left;
      line-height: 35px;
      vertical-align: middle;
      font-style: normal;
    }

    &:after {
      display: none;
    }
  }

  .link-wrapper {
    margin: 0 40px;
    position: relative;
    height: 100%;
    text-align: center;

    a.logo {
      .center-vertical-block();
      border-radius: 16px;
      padding: 3px 6px;
      margin: 0;

      // glow
      .press-glow();
    }
  }

  .menu-button, .back-button, .auth-button, .profile-button, .search-button {
    position: absolute;
    top: @top;

    a {
      .app-icon();

      width: @icon-size;
      height: @icon-size;
      border-radius: @icon-size/2;
      padding: 5px;

      //content: data-uri('./images/ico_menu.png');
      .image-content('./images/ico_menu.png');

      .press-glow();
    }
  }

  .back-button {
    right: 0;
    bottom: 0;

    a {
      //content: data-uri('./images/ico_back_shadow.png');
      .image-content('./images/ico_back_shadow.png');
    }
  }

  .menu-button {
    left: 5px;
    bottom: 0;

    a {
      //content: data-uri('./images/ico_menu.png');
      .image-content('./images/ico_menu.png');
    }
  }
}
