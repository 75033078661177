.image-content (@image-path) {
  //content: data-uri(@image-path);

  background-image: data-uri(@image-path);
  //background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.image-content-full (@image-path) {
  width: image-width(@image-path);
  height: image-height(@image-path);

  .image-content (@image-path);
}

.fullscreen() {
  position: absolute;
  position: fixed;
  top:0;
  left:0;
  right: 0;
  bottom: 0;

  z-index: -1;

  width: 100%;
  //backface-visibility: hidden;
  //-webkit-backface-visibility: hidden;
}

.app-icon-impl(@icon-size: 32px, @shadow: true) {

  @icon-size-half: (@icon-size / 2);
  width: @icon-size;
  height: @icon-size;
  background-size: @icon-size @icon-size;
  background-position: center center;
  background-repeat: no-repeat;
  display: inline-block;
  border-radius: @icon-size-half;
  z-index: 1;

  & when (@shadow = true) {
    .press-glow();
  }
}

.app-icon() {
  .app-icon-impl(32px, true);
}

.press-glow() {
  @media @phone {
    &:active {
      border: 1px solid rgba(255, 255, 255, 0.5);
      box-shadow: 0 0 5em white, 0 0 5em white, 0 0 10px white inset;
    }
  }
}