.karplayer {
  width: 100%;
  height: 100%;
  min-height: 300px;

  .center-vertical-block() {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .kar-title {
    text-align: center;
    font-size: 1.5em;

    position: absolute;
    left: 60px;
    right: 60px;
  }

  .kar-button-skip {
    float: right;

    position: absolute;
    right: 25px;
    width: 32px;

    .btn {
      color: white;
    }
  }

  .kar-text {
    text-align: center;
    /*font-size: 2em;*/
    font-size: 7vw;
    color: white;


    min-height: 160px;

    //position: absolute;
    //margin-top: 100px;
    //left: 60px;
    //right: 60px;

    // для возможности подсчёта максимальной ширины
    overflow: hidden;
    max-height: 160px;

    .karaokeBlock {
      visibility: hidden;
      display: inline-block;
    }
  }

  // загрузили - приводим в начальное состояние
  .kar-text.ready {
    overflow: auto;
    max-height: none;

    .karaokeBlock {
      visibility: visible;
      display: none;
    }

    // пропеваемый блок
    .karaokeBlock.speakingBlock {
      .center-vertical-block();
      display: inline-block;
      width: 100%;

      span[data-begin] {
        transition: color ease-in;
        -webkit-transition: color ease-in;
        //transition-timing-function: cubic-bezier(1,.08,1,-0.2);
        //-webkit-transition-timing-function: cubic-bezier(1,.08,1,-0.2);
        transition-duration: 5000ms;
        -webkit-transition-duration: 5000ms;
      }

      span[data-begin].speaking {
        //color: #ffba2d;
        color: #2a9fd6;
      }

      span[data-begin].speaking.finish {
        color: #2a9fd6;
        transition: none;
        -webkit-transition: none;
      }
    }
  }

  .kar-button-main {
    //position: absolute;
    //bottom: 10px;
    //right: 40px;
    float: right;
    margin-top: -35px;
    margin-left: 20px;

    .btn {
      margin-top: 1em;
      width: 60px;
      height: 60px;
      display: block;
      color: white;
      border-radius: 3em;
    }

    // замена шрифтов
    .stop, .pause, .play, .load {
      width: 64px;
      height: 64px;
      background-size: 64px 64px;
      margin-top: -10px;
      margin-left: -15px;

      overflow: hidden;

      .fa {
        display: none;
      }
    }

    .stop {
      //margin: -19px 0 0 -15px;
      //font-size: 4em;
      background-image: data-uri("./images/player_play.png");
    }

    .pause {
      //margin: -19px 0 0 -15px;
      //font-size: 4em;
      background-image: data-uri("./images/player_play.png");
    }

    .play {
      //margin: -5px 0 0 -14px;
      //font-size: 2.5em;
      background-image: data-uri("./images/player_stop.png");
    }

    .load {
      //margin: -10px 0 0 -2px;
      //font-size: 3em;
      background-image: data-uri("./images/player_wait.png");
      -webkit-animation: fa-spin 2s infinite linear;
      animation: fa-spin 2s infinite linear;
    }

    @-webkit-keyframes fa-spin {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
      }
    }
    @keyframes fa-spin {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
      }
    }
  }

  .kar-progress {
    //position: absolute;
    //bottom: 10px;
    //left: 25px;
    //right: 150px;


    .progress {
      height: 5px;
      background: #ffffff;
      margin-bottom: 5px;
    }

    .kar-progress-begin {
      float: left;

      color: white;
      text-shadow: #000011;
      font-size: 0.8em;
    }

    .kar-progress-end {
      float: right;

      color: white;
      text-shadow: #000011;
      font-size: 0.8em;
    }
  }



  .passage.speaking {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .passage p {
    line-height: 1.4em;
  }

  .passage {
    position: relative;

    min-height: 5em;

    .error {
      color: red;
    }

  }

  .control-block {

    font-size: 0.9em;
    margin: 10px 15px 0;
    padding: 10px;

    .cover {
      border-radius: 50%;
      width: 45px;
      height: 45px;
    }

    .artist {
      font-size: 0.7em;
    }

    .song {
      margin-top: 0;
      margin-bottom: 5px;
    }

    .category {

      font-size: 0.8em;
      margin-bottom: 10px;

      .icon {
        background-image: data-uri('./images/ico_category_popular.png');
      }

      span {
        vertical-align: middle;
      }
    }
  }

  .icon {
    display: inline-block;
    vertical-align: middle;

    width: 24px;
    height: 24px;
    background-size: 24px 24px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .play-next {
    color: white;
    margin: 10px 0 0 0;
    padding: 2px 10px 2px 2px;

    .icon {
      background-image: data-uri('./images/ico_next.png');
    }

    span {
      vertical-align: middle;
    }
  }

  a.play-next:hover {
    background: #2a9fd6;
  }
}
