.promo-triple {
  width: 100%;
  height: 120px;

  .cover-wrapper {
    width: 120px;
    vertical-align: top;
    padding-top: 2px;

    .cover {
      width: 124px;
      height: 124px;
    }

    .cover:before {
      width: 124px;
      height: 124px;
    }
    /*.cover:after {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      content: '';
      background-image: data-uri('./images/ico_play_cover.png');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 50px 50px;
    }*/
  }

  .songs-wrapper {
    overflow: auto;

    table {
      width: 100%;
      height: 100%;

      .item {
        height: 40px;
      }
    }
  }

  .author, .title {
    .font-simple;
    margin-left: 7px;
  }

  .author {
    margin-top: 2px;
    font-weight: 200;
    font-size: 0.75em;
    line-height: 0.9em;
  }

  .title {
    font-weight: 400;
    font-size: 1em;
  }

  .cover {
    display: block;
    /*    position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;*/
    //background-color: silver;
  }

  &.section-popular .selected {
    background-color: @section-popular-bg-color;
    border-color: @section-popular-border-color !important;
  }

  &.section-playlist .selected {
    background-color: @section-playlist-bg-color;
    border-color: @section-playlist-border-color !important;
  }

  &.section-new .selected {
    background-color: @section-new-bg-color;
    border-color: @section-new-border-color !important;
  }

  table& {
    border-collapse: separate;
    border-spacing: 0 1px;

    background-color: transparent;

    td {
      table {
        background-color: transparent;
        border-collapse: separate;
        border-spacing: 0 2px;

        td {
          cursor: pointer;
          background-color: rgba(102, 102, 102, 0.6);
        }
      }
    }
  }
}
