.song-batch-carousel {
  display: block;
  width: 100%;
  height: 120px;
  background: rgba(0, 0, 0, 0.6);

  .rn-carousel-controls {

    .rn-carousel-control.rn-carousel-control-prev {
      width: 40px;
      height: 100%;
      top: 0;
      left: 0;
    }

    .rn-carousel-control.rn-carousel-control-next {
      width: 40px;
      height: 100%;
      top: 0;
      right: 0;
    }

    .rn-carousel-control.rn-carousel-control-prev:before {
      padding: 40px 0;
      display: block;
      content: data-uri('./images/ico_arrow_left.png');
    }

    .rn-carousel-control.rn-carousel-control-next:before {
      padding: 40px 0;
      display: block;
      content: data-uri('./images/ico_arrow_right.png');
    }
  }

  table {
    width: 100%;
    background: transparent;

    td {
      align-content: center;
      text-align: center
    }
  }
}