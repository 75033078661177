@import "../karaoke/common/media";
@import "mixins.less";
/*@-webkit-keyframes androidBugfix {from { padding: 0; } to { padding: 0; }}
body { -webkit-animation: androidBugfix infinite 1s; }*/

#redraw-fix {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 25;
  pointer-events: none;
  display: block;
}

html, body, #imageBackground {
  margin:0;
  padding:0;
  width: 100%;
  height: 100%;
}


#main, .page {
  width: 100%;
  height: 100%;
  display: table;
}

// new context
.context {
  z-index: 1;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: scale(1);
}

.template-header, .template-footer, .page-block {
  display: table-row;
  width: 100%;
  height: 0;
}

.template-main, .page-block-main {
  display: table-row;
  width: 100%;
  height: auto;
}


/*#main {
  -webkit-transform: scale(1);
}*/

// Bootstrap

.center-vertical-block {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.jumbotron {
  background-color: rgba(21, 21, 21, 0.25);
  border: 1px #6c6c6c solid;
  padding: 15px 15px;
  margin-bottom: 0;
}

.bootbox {
  -webkit-transform: scale(1);

  &.modal.large {
    .modal-dialog {
      margin: 5px auto;
      .modal-content {
        margin: 0 5px;
        .modal-body {
          padding: 15px 5px 5px 5px;
        }
      }
    }
  }

  input {
    width: 100%;
    border: 1px solid #a8a8a8;
    padding: 5px 7px;
    margin-bottom: 0.5em;
  }

  .checkbox input {
    width: unset;
  }

  &.document .bootbox-body {
    text-align: left;
  }

  .headline {
    display: block;
    font-weight: bold;
    font-size: 2em;
    text-align: center;
  }

  .chapter {
    display: block;
    padding-top: 1em;

    & > .title {
      display: block;
      font-weight: bold;
      font-size: 1.1em;
    }
  }
}

// специальные стили

.font-simple {
  font-family: Helvetica, Arial, sans-serif;
}

.label-capital {
  text-transform: uppercase;
}

a[ng-click], a[data-ng-click] {
  cursor: pointer;
}

.app-label {
  .font-simple;

  font-size: 1.2em;
  text-shadow: 2px 2px 2px #333333;
  font-weight: 100;

  line-height: 1.2em; // =(

  padding: 0 5%;

  &.search-label {
    font-size: 1.5em; padding: 0; margin-top: 0.5em
  }

  &.promo {
    font-size: 1em;
    text-align: center;
  }
}

.app-icon {
  .app-icon();
}

.button-image {
  display: block;
  width: 220px;
  height: auto;
  //height: 38px;
  //background-size: 100% auto;
  //background-repeat: no-repeat;
  //background-position: center center;

  box-sizing: content-box;
  padding: 5px;

  border-radius: 5px;

  &:active {
    background-color: rgba(255, 255, 255, 0.8);
    //border-radius: 5px;
  }
}

@import "element-disclaimer.less";

.button-send {
  .button-image;
  //content: data-uri('./images/btn_send_small.png');
  //.image-content('./images/btn_send_small.png');
  width: 190px;
  height: 36px;
}

// фон страницы

#background {
  .fullscreen();

  z-index: -2;

  //background: rgba(255, 255, 255, 1) no-repeat center center fixed;
  background: black;
  background-repeat: no-repeat;
  background-position: center center;
  //background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  &:after {
    //.fullscreen();
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
  }
}

// affix-панель моих песен

.playlist-panel {
  //background: white;
  //color: black;
  //border: whitesmoke 1px solid;
  //margin: 1em;
  //padding: 1em;

  &.affix {
    z-index: 100;
    box-shadow: 0 2px 4px black, 0 2px 4px black;

    //position: static;
    //width: auto;

    //position: fixed !important;
    top: 0;
    left: 10px;
    right: 10px;
    width: auto;

    animation: affixFadeIn 0.44s  ease-in;
    -webkit-animation: affixFadeIn 0.44s;

    .app-header-line {
      margin: 0;
    }
  }

  @keyframes affixFadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }

  @-webkit-keyframes affixFadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }
}

// обратная связь внизу страницы

.feedback-block {
  .font-simple;

  display: block;
  margin-top: 0.5em;
  text-align: center;
  line-height: 0; // 1em
  color: @karaoke-text-color;

  margin-right: 5px;
  min-height: 44px;

  .app-icon {
    background-image: data-uri('./images/ico_feedback.png');
    vertical-align: middle;
  }
}

.terms-block {
  .font-simple;

  display: block;
  margin: 0 0.5em;
  text-align: center;
  line-height: 1em;
  color: @karaoke-text-color;

  min-height: 2.5em;
}

// картинка успешного исполнения

.art-play-finish {
  margin: 1em auto;
  width: 142px;
  height: 142px;
  background-size: 142px 142px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: data-uri('./images/art_play_finish.png');
}

.player-content {
  font-size: 1.5em;

  .karplayer {
    .control-block {
      padding-top: 30px;
    }
  }
}

.player-finish {

  .app-label {
    font-size: 1.5em;
    margin-top: 20px;
  }

  .btn-primary {
    margin: 1em 0.5em;
  }
}

// удаление обводки в chrome
.btn:focus, button:focus {
  outline: 0 !important;
}

// базовый стиль для кастомных тегов
.component {
  display: block;
}



@desktop-offset: 52px;

.content {
  box-sizing: border-box;
  position: relative;
  min-height: 1px;
  padding-left: 18px;
  padding-right: 18px;

  &.page-block > * {
    margin-left: 18px;
    margin-right: 18px;
  }
}

@media @desktop {
  //.page-block-main > .col-md-6.col-md-offset-3, .page-block > .col-md-6.col-md-offset-3 {
  .page-block-main > .content, .page-block > .content {
    //margin-left: 0;
    //margin-right: 0;
    //float: left;
    margin-left: auto;
    margin-right: auto;

    padding-left: 52px;
    padding-right: 52px;
    width: 100%;
    position: relative;
    min-height: 1px;
  }
}
/*@media only screen and (min-width: 992px) {
  .profile form {
    height: 100%;
  }
}*/

@media @desktop {
  //.col-md-offset-3 {
  .page-block > .content {
    margin-left: 25%;
  }

  //.col-md-6 {
  .page-block > .content {
    width: 50%;
  }

  //.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
  .page-block > .content {
    float: left;
  }
}

@media @desktop {
  .page-block > .content {
    margin-left: 0;
    margin-right: 0;
    padding-left: @desktop-offset;
    padding-right: @desktop-offset;
    width: 100%;
    float: left;
    position: relative;
    min-height: 1px;
  }

  /*.page-block.content {
    margin-left: @desktop-offset;
    margin-right: @desktop-offset;
  }*/
}

.page-block-main.content > :first-child,
.page-block-main > .content > :first-child
//.page-block > .content > :first-child
{
  margin-top: 24px;
  margin-bottom: 24px;
}
