.song-item {
  display: block;
  position: relative;
  background: rgba(255, 255, 255, 0.1); //rgba(55, 55, 55, 0.6);

  border-radius: 0;
  text-align: left;

  color: white;
  margin: 5px 0;
  padding: 0;

  font-size: 0.8em;

  min-height: 48px;

  & > a, & > a.content {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }

  .content {
    display: block;
    padding: 10px 0 5px;
  }

  .playlist-action {
    &.playlist-added, &.playlist-add {
      display: none;
    }
  }

  &.with-playlist {
    .content {
      margin-right: 56px;
    }

    .playlist-action {
      margin: 10px;
      &.playlist-added, &.playlist-add {
        display: inline-block;
      }
    }
  }

  /*&:active {
    border: 1px solid rgba(255, 255, 255, 0.7);
  }*/

  a:hover, a:active {
    text-decoration: none;

    .author, .name {
      //color: #70a5ee;
    }
  }

  /*.fa {
    position: absolute;
    top: 15px;
    left: 20px;
    font-size: 32px;
  }*/

  .name {
    margin-left: 56px; // icon
    white-space: pre-wrap;
    font-size: 1.4em;
    color: #ffffff;
  }

  .author {
    margin-left: 56px; // icon
    white-space: pre-wrap;
    font-size: 1em;
    color: #eeeeee;
  }

  .catalog {
    margin-left: 56px; // icon
    white-space: pre-wrap;
    font-size: 1em;
    color: #eeeeee;
    display: none;
  }

  .play {
    .app-icon();

    float: left;
    margin: 0 12px;

    background-image: data-uri('./images/ico_mic.png');
  }

  .playlist-added {
    .app-icon();

    float: right;
    margin: 0 12px;

    background-image: data-uri('./images/ico_check.png');
  }

  .playlist-add {
    .app-icon();

    float: right;
    margin: 0 12px;

    background-image: data-uri('./images/ico_plus.png');
  }
}
