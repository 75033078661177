@media @desktop {
  .disclaimer {

    background-color: @karaoke-tcell-grey;

    border-bottom: 2px @karaoke-tcell-dark-grey solid;
    border-top: 2px @karaoke-tcell-dark-grey solid;

    color: @karaoke-tcell-light-black;

    .app-label:first-child {
      font-weight: bold;
    }

    .app-label {
      float: left;
      margin: 4px 0;
      background-color: transparent;
      font-size: 16px;
      text-align: left;
      padding-left: @desktop-offset;
      padding-right: 4px;
    }

    .button-subscribe {
      background-color: transparent;
      border-color: @karaoke-tcell-white;
      color: @karaoke-tcell-purple;

      &:after {
        color: @karaoke-tcell-purple;
      }

      &:hover:after {
        color: @karaoke-tcell-white;
      }
    }

    .center-block {
      float: right;
      margin: 6px @desktop-offset 6px 0;
    }

    &:after {
      content: '';
      display: block;
      clear: both;
      width: 100%;
      height: 0;
    }
  }
}