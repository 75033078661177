@cover-size: 120px;

.cover {
  .loader;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: @cover-size;
  height: @cover-size;
  margin: auto;
  display: inline-block;
  position: relative;

  .description {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    text-align: left;
    background: rgba(0, 0, 0, 0.6);
    padding: 4px 4px;

    .author {
      font-size: 0.8em;
      line-height: 0.9em;
      margin-bottom: 4px;
      display: block;
    }

    .title {
      font-size: 1em;
      line-height: 0.9em;
      display: block;
    }
  }

  &:hover {
    box-shadow: #1176ee 0 0 3em;
  }
}